<template>
  <div class="cabinet main-frame" :class="isEn?'cabinet-en':''">
    <!-- 内容1 -->
    <div class="cont-1 relative">
      <!-- <el-image  lazy   v-if="isEn" :src="require('../assets/product/11-en.jpg')" :alt="$t('zh1_1')" class='banner-1'/> -->
      <el-image lazy :src="require('../assets/product/product.png')" :alt="$t('zh1_1')" class='banner-1'/>
    </div>
    <!-- 内容2 -->
    <div class="cont-2 padd100-100">
      <h2 class="h-1 gapB80 fw700 fz40">{{$t("product_c_2_h1")}}</h2>
      <div class="cont-2-box">
        <el-carousel trigger="click" :interval="5000" height="560px">
          <el-carousel-item v-for="(item,index) in images" :key="index">
            <img :src="item.imgUrl"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      
    </div>
    <!-- 内容3 -->
    <div class="cont-3 padd100-100">
      <h2 class="h-1 gapB80 fw700 fz40">{{$t("product_c_3_h1")}}</h2>
      <div class="home-con">
        <div class="cont-3-prod gapB40">
          <h4 class="h-7 fw700 fz28 left">{{$t("product_c_3_t1")}}</h4>
          <div class="ul-prod">
            <ul class="nav">
              <li 
              v-for="(item ,index) in tabs1"
              @mouseenter.stop="curTab1 = index"
              :key="index">
                <span class="outLi left" :class="{ active: index===curTab1,fz24:true,fw700:index===curTab1}">{{$t(item.name)}}</span>
              </li>
            </ul>
            <div class="img-warp">
              <transition name="fade">
                <img :src="station_img" class='prod-1'/>
              </transition>
            </div>
            
          </div>
        </div>
        <div class="cont-3-prod">
          <h4 class="h-7 fw700 fz28 left">{{$t("product_c_3_t2")}}</h4>
          <div class="ul-prod">
            <ul class="nav">
              <li 
              v-for="(item ,index) in tabs2"
              @mouseenter.stop="curTab2 = index"
              :key="index">
                <span class="outLi left" :class="{ active: index===curTab2,fz24:true,fw700:index===curTab2}">{{$t(item.name)}}</span>
              </li>
            </ul>
            <div class="img-warp">
              <transition name="fade">
                <img :src="bat_img" class='prod-1'/>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 内容4 -->
    <div class="cont-4 relative white padd100-100">
      <h2 class="h-1 gapB80 fw700 fz40">{{$t("product_c_4_h1")}}</h2>
      <div class="home-con">
        <ul class="ul-content">
          <li v-for="(item,index) in photoData" class="relative" :key="index">
            <img :src="item.imgUrl" alt=""/>
            <h2 class="p1 fz24 fw700">{{$t(item.name)}}</h2>        
          </li>
        </ul> 
      </div>
    </div> 
  </div>
</template>

<script>
export default {
  computed:{
    isEn:function(){
      return this.$store.state.isEn
    },
    station_img(){
      return this.tabs1[this.curTab1].img;
    },
    bat_img(){
      return this.tabs2[this.curTab2].img;
    },
  },
  data(){
    return{
      n:0,
      photoData:[
        {
          imgUrl:require('../assets/product/con_4_img3.png'),
          name:'product_c_4_t1'
        },
        {
          imgUrl:require('../assets/product/con_4_img5.png'),
          name:'product_c_4_t2'
        },
        {
          imgUrl:require('../assets/product/con_4_img6.png'),
          name:'product_c_4_t3'
        },
        {
          imgUrl:require('../assets/product/con_4_img1.png'),
          name:'product_c_4_t4'
        },
        {
          imgUrl:require('../assets/product/con_4_img4.png'),
          name:'product_c_4_t5'
        },
        {
          imgUrl:require('../assets/product/con_4_img2.png'),
          name:'product_c_4_t6'
        },
      ],
      images:[
        {
          imgUrl:require('../assets/product/con_2_img1.png'),
        },
        {
          imgUrl:require('../assets/product/con_2_img2.png'),
        },
      ],
      curTab1:0,
      curTab2:0,
      tabs1:[
        {
          name:"product_c_3_tab1_1",
          img:require('../assets/product/station_5.png')
        },
        {
          name:"product_c_3_tab1_2",
          img:require('../assets/product/station_6.png')
        },
        {
          name:"product_c_3_tab1_3",
          img:require('../assets/product/station_15.png')
        },
        {
          name:"product_c_3_tab1_4",
          img:require('../assets/product/station_18.png')
        },
      ],
      tabs2:[
        {
          name:"product_c_3_tab2_1",
          img:require('../assets/product/bat_6035.png')
        },
        {
          name:"product_c_3_tab2_2",
          img:require('../assets/product/bat_6060.png')
        },
        {
          name:"product_c_3_tab2_3",
          img:require('../assets/product/bat_4835.png')
        },
        {
          name:"product_c_3_tab2_4",
          img:require('../assets/product/bat_4860.png')
        },
      ],
    }
  },
  created(){
		
	},
  methods:{
    
  }
};
</script>


<style lang="scss" scoped>

//英文样式
.cont-1 .p1{
  color: #30363e;
}
.banner-1{
  height:700px;
}

// 公共
.home-con{
  display: flex;
  justify-content: center;
  width: 100%;
}
.ul-content{
  width:1200px;
  display: flex;
}

// 内容2 
.cont-2{
  background-image: url(../assets/product/con_2_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  box-sizing: border-box;
  .cont-2-box{
    height: 560px;
    width: 1360px;
    margin: 0 auto;
  }
  .el-carousel {
      ::v-deep .el-carousel__button {// 指示器按钮
          background-color: #d9dce2;
      }
      ::v-deep .is-active .el-carousel__button {// 指示器激活按钮
          background-color: #165dff;
      }
  }
}

// 内容3 
.cont-3{
  box-sizing: border-box;
  background: #f8f9fb;
  .home-con{
    flex-wrap: wrap;
  }
  .cont-3-prod{
    height: 654px;
    width: 1200px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 40px 0px rgba(0,0,0,0.05);
    padding: 2px;
    box-sizing: border-box;
    h4{
      line-height: 32px;
      padding: 34px 0 30px 42px;
      background: linear-gradient(180deg,rgba(206,219,235,0.80), rgba(206,219,235,0.20) 100%);
    }
    .ul-prod{
      display: flex;
      .nav{
        padding-top: 12px;
        .outLi {
          position: relative;
          width: 130px;
          padding: 24px 118px 24px 28px;
          color: #393f47;
          line-height: 32px;
          display:inline-block;
          &.active{
            color: #165DFF;
          }
          &.active::before {
            content: '';
            width: 4px;
            height: 48px;
            background: #165DFF;
            position: absolute;
            top: 16px;
            left: 2px;
            right: 0;
          }
        }
      }
      .img-warp{
        flex: 1;
        height: 554px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg,#cedbeb, rgba(206,219,235,0.20) 100%);
      }
      .prod-1{
        width: 480px;
        height: 480px;
      }
    }
  }
}
// 内容4
.cont-4{
  .ul-content{
    flex-wrap: wrap;
    justify-content: space-between;
    li{
      width: 387px;
      height: 216px;
      img{
        width: 387px;
        height: 216px;
      }
      h2{
        position: absolute;
        left: 32px;
        top: 24px;
        letter-spacing: 2px;
      }
    }
    li:nth-child(1),
    li:nth-child(2),
    li:nth-child(3){
      margin-bottom: 20px;
    }
  }
}

// 段落2
.cont-2 .p2 {
  text-align: left;
  color: #16193c;
}

// 内容1
.text-1 {
  position: absolute;
  top: 50%;
  left: 21%; 
  right:56%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.cont-1 .h-1{
  text-align: left;
}
// 内容2
.cont-2 img {
  display: inline-block;
}
/*IPone 991*/
@media (max-width: 1440px) {
  .text-1{
    left:15%
  }
}
@media (max-width: 992px) {
  // 内容1
  .text-1 {
    position: relative;
    top:0;
    left: 0;
    right:0;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
</style>